import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";

@Injectable()
export class InspectionService {
  constructor(private http: HttpClient) {

  }

  updateInspectionDates(dto: any): Promise<any> {
    const headers = {
      'Content-Type': 'application/json',
    };


    return new Promise((resolve, reject) => {

      this.http.post<any>(environment.apiUrl + "inspection/UpdateInspectionDates", dto, { headers: headers }).subscribe(data => {
        console.log(data);
        resolve(data);
      }, (error) => {
        console.log(error);
        reject(error.error)
      });
    });
  }


  getImages(inspectionId : number) : Observable<[]> {
    const headers = {
      'Content-Type': 'application/json',
    };

    var httpParams = new HttpParams()
    //.set('inspectionId', inspectionId.toString());
    .set('inspectionId', "513568");

    return this.http.get<[]>(environment.apiUrl + "image/GetInspectionImagesForView", { 
      headers: headers,
      params: httpParams
    });
  }

  notifyRanger(inspectionIds: any, notifyType: string, notes: string, requestedBy: string) : Promise<boolean> {
    const headers = {
      'Content-Type': 'application/json',
    };

    var dto = {
      inspectionIds: inspectionIds,
      notes: notes,
      notifyType: notifyType,
      requestedBy: requestedBy
    }

    return new Promise((resolve, reject) => {
      this.http.post<any>(environment.apiUrl + "inspection/notifyranger", dto, { headers: headers }).subscribe(data => {
        resolve(true);
      }, (error) => {
        resolve(false)
      });
    });
  }

  notifyLocations(companyId: any, locationIds: any, requestedBy: string, notes: string) : Promise<boolean> {
    const headers = {
      'Content-Type': 'application/json',
    };

    var dto = {
      companyId: companyId,
      locationIds: locationIds,
      requestedBy: requestedBy,
      notes: notes
    }

    return new Promise((resolve, reject) => {
      this.http.post<any>(environment.apiUrl + "inspection/notifyLocations", dto, { headers: headers }).subscribe(data => {
        resolve(true);
      }, (error) => {
        resolve(false)
      });
    });
  }
}
