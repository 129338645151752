<div class="download-container">
  <h1>Update Dates</h1>

  <div class="download-option">
    <label  style="width:160px">Next Inspection Date<br/>(Certificate)</label>
    <div class="download-input">
      <input matInput [matDatepicker]="pickerStart" [(ngModel)]="nextInspectionDate">
      <mat-datepicker-toggle matSuffix [for]="pickerStart"></mat-datepicker-toggle>
      <mat-datepicker #pickerStart></mat-datepicker>
    </div>
  </div>

  <div class="download-option">
    <label style="width:160px">Next Inspection Period<br />(Home Page)</label>
    <div class="download-input">
      <input matInput [matDatepicker]="pickerEnd" [(ngModel)]="nextInspectionPeriod" style="width: 0px; flex: 0; padding: 5px;">
      <div  style="flex: 1;;">{{ nextInspectionPeriod | date: 'MMM YYYY' }}</div>
      <mat-datepicker-toggle matSuffix [for]="pickerEnd"></mat-datepicker-toggle>
      <mat-datepicker #pickerEnd startView="multi-year" (monthSelected)="closeDatePicker($event, pickerEnd)"></mat-datepicker>
    </div>
  </div>


  <div class="action-buttons">
    <button class="btn-save" type="button" (click)="update()">
      Update
    </button>

    <button class="btn-cancel" type="button" (click)="cancel()">
      CANCEL
    </button>
  </div>

</div>
