import { Component, Inject, Input, OnDestroy, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ReportableService } from 'src/app/services/reportable.service';
import { saveAs as importedSaveAs } from 'file-saver';
import * as moment from 'moment';
import { LocationService } from 'src/app/services/administration/location.service';
import * as JSZip from 'jszip';
import { pdfDocEncodingDecode, PDFDocument } from 'pdf-lib'
import { resolve } from 'dns';
import { AuthService } from 'src/app/services/auth.service';
import * as signalR from "@microsoft/signalr"
import { environment } from 'src/environments/environment';
import { HttpClient, HttpEventType } from '@angular/common/http';
import { SnotifyService } from 'ng-snotify';
import { InspectionService } from '../../services/inspection.service';

@Component({
  selector: 'app-inspection-update-dates',
  templateUrl: './inspection-update-dates.component.html',
  styleUrls: ['./inspection-update-dates.component.scss']
})
export class InspectionUpdateDatesComponent implements OnInit, OnDestroy {

  nextInspectionDate: string = '';
  nextInspectionPeriod:string = '';

  constructor(public dialogRef: MatDialogRef<InspectionUpdateDatesComponent>, private reportableService: ReportableService, private locationsService: LocationService,
    @Inject(MAT_DIALOG_DATA) public data: { inspections: any, allInspections: any, share: boolean, user: any },
    private authService: AuthService, private snotifyService: SnotifyService, private inspectionService: InspectionService,
    private http: HttpClient) { }

  ngOnInit(): void {
    console.log(this.data.user);
    if (this.data.inspections.length == 0) {
      this.snotifyService.error("Please select at least 1 inspection to update");
      this.dialogRef.close();
    } else {
      var inspection = this.data.inspections[0];
  

      this.nextInspectionDate = inspection.nextInspectionDate;
      if (inspection.nextInspectionPeriod != null) {
        this.nextInspectionPeriod = moment(inspection.nextInspectionPeriod).format("YYYY-MM-DD").toString();
        console.log("NOT NULL");
      }
      else {
        this.nextInspectionPeriod = inspection.nextInspectionDate;
        console.log("IS NULL");

      }
      
      console.log("this.data.inspections[0]");
      console.log(this.data.inspections[0]);
      
    }


  }


  openDatePicker(dp) {
    dp.open();
  }

  closeDatePicker(eventData: any, dp?: any) {
    console.log(eventData);
    this.nextInspectionPeriod = eventData.format("YYYY-MM-DD").toString();
    // get month and year from eventData and close datepicker, thus not allowing user to select date
    dp.close();
  }

  ngOnDestroy(): void {
 
  }

  cancel() {
    this.dialogRef.close();
  }
  update() {

    var dto = {
      userId: this.data.user.userId,
      nextInspectionDate: moment(this.nextInspectionDate).format("YYYY-MM-DD").toString(),
      nextInspectionPeriod: this.nextInspectionPeriod,
      inspectionIds: this.data.inspections.map(i => i.inspectionId)
    }
    console.log(dto);

    this.inspectionService.updateInspectionDates(dto).then((data) => {
      this.snotifyService.success("Inspection Dates have been updated");
      this.dialogRef.close(true);
    }, (err) => {
      this.snotifyService.error(err);
    });
  }

}
