import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import * as moment from 'moment';
import { SnotifyService } from 'ng-snotify';
import { LocationService } from '../../services/administration/location.service';
import { AuthService } from '../../services/auth.service';
import { ReportableService } from '../../services/reportable.service';
import { HttpClient, HttpEventType } from '@angular/common/http';

@Component({
  selector: 'app-pin-confirmation',
  templateUrl: './pin-confirmation.component.html',
  styleUrls: ['./pin-confirmation.component.scss']
})
export class PINConfirmationComponent implements OnInit, OnDestroy {
  enteredPIN: string = "";;


  constructor(public dialogRef: MatDialogRef<PINConfirmationComponent>, private reportableService: ReportableService, private locationsService: LocationService,
    private authService: AuthService, private http: HttpClient, private snotifyService: SnotifyService) { }

  ngOnInit(): void {
    
  }

  ngOnDestroy(): void {
 
  }

  cancel() {
    this.dialogRef.close(false);
  }
  update() {
    this.reportableService.checkAdminPIN(this.enteredPIN).then((data) => {
      this.dialogRef.close(true);
    }, (err) => {
      this.snotifyService.error("Admin PIN was incorrect");
    });
  }
}
