<div class="download-container">
  <h1>Confirm PIN</h1>

  <div class="download-option">
    <label>Please enter the Admin PIN</label>
    <div class="download-input">
      <input type="password" [(ngModel)]="enteredPIN" autocomplete="off"/>
    </div>
  </div>


  <div class="action-buttons">
    <button class="btn-save" type="button" (click)="update()">
      SUBMIT
    </button>

    <button class="btn-cancel" type="button" (click)="cancel()">
      CANCEL
    </button>
  </div>

</div>
